// DOM 초기화 완료 시 이벤트 실행
const $ = q => document.querySelector(q), $$ = q => document.querySelectorAll(q);
addEventListener('DOMContentLoaded', () => {
	const blocks = {
  	PRE: {
      mask: IMask.MaskedEnum,
    	enum: ['010', '011', '016', '017', '018', '019']
    }
  }, maskOptions = {
		mask: [
			{ mask: 'PRE-000-0000', blocks: blocks },
			{ mask: 'PRE-0000-0000', blocks: blocks },
		]
	};
	$$('.phone-mask').forEach(input => {
		input.MASK = IMask(input, maskOptions);
	});
	$$('.contact-form').forEach(form => {
		form.addEventListener('submit', e => {
			e.preventDefault();e.stopPropagation();
			const formData = new FormData(form);
			const data = [...formData.entries()];
			//console.log('data', data);
			//data.map((a) => !isNaN(+a[1]) && (a[1] = '`' + a[1]));
			const inputs = [...form.querySelectorAll('input,button,select')]
			inputs.forEach(input => (input.disabled = true));
			fetch('https://server.instamonsterpro.com/alim/kakao/instamonster/send', {
				method: form.method,
				headers: new Headers({
					"Content-Type": form.enctype,
				}),
				body: data.map(([k,v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&')
			}).then(res => {
				if (res.ok) return res.json();
				else if(res.status === 400) {
					return res.json();
				}
				else throw new Error(res.status);
			}).then(json => {
				if (json.result) {
					data.unshift(['insert_at', new Date().toLocaleString('sv').replace(/\D/g,'')]);
					data.push(['memo', 'instamonsterpro.com']);
					return fetch(form.action, {
						method: form.method,
						headers: new Headers({
							"Content-Type": form.enctype,
						}),
						body: data.map(([k,v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&')
					})
				} else throw new Error('!' + json.message);
			}).then(res => {
				if (res.ok) return res.json();
				else throw new Error(res.status);
			}).then(json => {
				if (json.result === 'success') {
					alert('상담 신청해 주셔서 감사합니다.\n관련 담당자가 전화 드리겠습니다.\n* 카카오톡에 마케팅 전자책을 보내드렸습니다.');
				} else throw new Error(json.result);
			}).catch(e => {
				console.debug(e);
				if (e && e.message && e.message[0] === '!') {
					alert(e.message.substring(1));
				} else alert('신청 전송이 실패되었습니다. 아래 기재된 제휴 이메일로 문의 보내주시면 빠른 상담 도와드리겠습니다. 감사합니다.');
				inputs.forEach(input => (input.disabled = false));
			}).finally(() => {
				// 완료 시 재전송 금지
			})
		})
	})
	const swiper = new Swiper('.review-slide', {
		// Optional parameters
		slidesPerView: 1,
		autoHeight: true,
    spaceBetween: 20,
		centeredSlides: true,
		slidesPerView: "auto",
		loop: true,
		// Navigation arrows
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		autoplay: {
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
			},
			// 1400: {
			// 	centeredSlides: false,
			// 	slidesPerView: 3,
			// 	spaceBetween: 10,
			// },
		},
	});
	function getElementY(query) {
		return window.scrollY + document.querySelector(query).getBoundingClientRect().top
	}
	function doScrolling(element, duration) {
		var startingY = window.scrollY
		var elementY = getElementY(element)
		// If element is close to page's bottom then window will scroll only to some position above the element.
		var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
		var diff = targetY - startingY
		// Easing function: easeInOutCubic
		// From: https://gist.github.com/gre/1650294
		var easing = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
		var start
		if (!diff) return
		// Bootstrap our animation - it will get called right before next frame shall be rendered.
		window.requestAnimationFrame(function step(timestamp) {
			if (!start) start = timestamp
			// Elapsed miliseconds since start of scrolling.
			var time = timestamp - start
			// Get percent of completion in range [0, 1].
			var percent = Math.min(time / duration, 1)
			// Apply the easing.
			// It can cause bad-looking slow frames in browser performance tool, so be careful.
			percent = easing(percent)
			window.scrollTo(0, startingY + diff * percent)
			// Proceed with animation as long as we wanted it to.
			if (time < duration) {
				window.requestAnimationFrame(step)
			}
		})
	}
	document.querySelectorAll('a[href^="#"]').forEach(a => {
		console.log(a)
		a.addEventListener('click', e => {
			e.preventDefault();
			e.stopPropagation();
			const index = a.href.indexOf('#');
			~index && doScrolling(a.href.substring(a.href.indexOf('#')), 1000)
		})
	})
})
